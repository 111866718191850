import moment from 'moment-timezone'
import { Web3Provider, getDefaultProvider, BaseProvider } from '@ethersproject/providers';

export default class UtilsManager { 

	constructor() { 

	}

	getLast14DaysMap(startState = 0, format="MMM Do YY") { 
		const dayMap = new Map()
		var current = moment(new Date()).subtract(14, 'days')

		const holder = []
		while (holder.length != 15) { 
			const day = moment(current).format(format)
			dayMap[day] = startState
			holder.push(day)
			current = moment(current).add(1, 'days')
		}
		return dayMap
	}

	getDaysBetweenMap(startDate, endDate, format="YYYY-MM-DD") { 
		const dayMap = {}
		var start = moment(startDate)
		var end = moment(endDate);
		var diff = end.diff(start, 'days') + 1;
		
		for (var i = 0; i<diff; i++) { 
			const day = moment(start).format(format)
			dayMap[day] = [];
			start = moment(start).add(1, 'days')
		}

		return dayMap
	}

	capitalizeFirstLetter(string) {
    	return string.charAt(0).toUpperCase() + string.slice(1);
	}

	getIntegerFromUrl(key) { 
		const regex = /(\d+$)/
		var matches = key.match(regex);
		if (matches !== null && matches !== undefined && matches.length !== 0 ) { 
			return matches[0]
		} else { 
			return null
		}
	}

	combineParams(params) { 
		if (params === null || params.length === 0) { 
			return ""
		}
		var paramStr = "?"
		for (let number = 0; number < params.length; number++) { 
			const param = params[number]
			paramStr += param.title + "=" + param.value 
			if (number < params.length - 1) { 
				paramStr += "&"
			}
		}

		return paramStr
	}

	getParamValues(value) {
		// Polyfill
		if (typeof Array.isArray === 'undefined') {
	  		Array.isArray = function(obj) {
	    		return Object.prototype.toString.call(obj) === '[object Array]';
	  		}
		}
		
		if (Array.isArray(value)) { 
			return value.join()
		} else { 
			return value
		}
	}

	combineParamsV2(params) { 
		if (params === null || params.length === 0) { 
			return ""
		}

		var paramStr = "?"
		for (let number = 0; number < params.length; number++) { 
			const param = params[number]
			for (var key in param) {
				paramStr += key + "=" + this.getParamValues(param[key])
			}
			if (number < params.length - 1) { 
				paramStr += "&"
			}
		}

		return paramStr
	}

	getZoneSlug(obj) { 
		var slug = null
		
		if (obj === null || obj === undefined) { 
			return null
		}
		if (obj.zone !== null && obj.zone !== undefined) { 
			slug = obj.zone.slug
		}
		return slug
	}

	getTimeZone(item, guess=false) { 
		const zone = this.getZone(item)
		if (zone !== null) { 
			return zone.time_zone
		} else {
			if (guess === true) { 
				return moment.tz.guess().toString()
			} 
			return null
		}
	}

	getZone(item) { 
		var zone = null
		if (item === null) { 
			return null
		}
		if (item.zone !== null && item.zone !== undefined) { 
			zone = item.zone
		}
		return zone 
	}

	async awaitElement(elementId, {shouldContinue = true, maxTries=7200} = {}) { 
		var tries = 0;

        while (shouldContinue && !document.getElementById(elementId) && tries < maxTries) {
			console.log(`Awaiting until element with id ${elementId} exists`)
			tries += 1;
            await new Promise(r => setTimeout(r, 500));
		}
		const result = document.getElementById(elementId);
		return result;
	}

	async await(func, waitResult, maxRetries=600) { 
		var tries = 0;
		while (func() === waitResult && tries <= maxRetries) {
			tries += 1;
            await new Promise(r => setTimeout(r, 500));
		} 
		return func();
	}

	
    findPosition(obj) { 
        var currenttop = 0; 
        if (obj.offsetParent) { 
            do { 
                currenttop += obj.offsetTop; 
            } while ((obj = obj.offsetParent)); 
            return currenttop; 
        } 
    } 
	
	tryOrDefault(func, defaultVal, useDefaultIfUndefined=true) {	
		try {
			var returnVal = func()
			if (useDefaultIfUndefined && returnVal == undefined) { 
				return defaultVal
			}
			return returnVal
		} catch(error) { 
			return defaultVal
		}
	}

	playVideo(vidElem, onSuccess, onFail) { 
		var promise = vidElem.play()
		if (promise !== undefined) {
			promise.catch(error => {
				// Auto-play was prevented
				// Show a UI element to let the user manually start playback
				onFail(error)
			}).then(() => {
				// Auto-play started\
				if (onSuccess) { 
					onSuccess()
				}
			});
		}
	}

	getRandomEntity(collection) {
		var randomIndex = Math.floor(Math.random() * (collection.length - 0) + 0);
		var randomEntity = collection[randomIndex]
		return randomEntity
	}

	getTruncatedText(text, maxLength) { 
		if (!text) { 
			return ""
		}
		if (text.length > maxLength) {
			return text.substr(0, maxLength) + "..."
		}
		return text;
	}
	
	getDateTimeStr(date, time) { 
		const dayFormat = "YYYY-MM-DD"
		const timeFormat = "HH:mm:ss"
		const dayStr = moment(date).format(dayFormat)
		const timeStr = moment(time).format(timeFormat)
		const dateTime = dayStr + "T" + timeStr
		return dateTime;
	}

	getTimeZoneConversion(startDate, startTime, endDate, endTime, timeZone) {
		const dayFormat = "YYYY-MM-DD";
		const timeFormat = "HH:mm:ss";
		const start = this.getDateTimeStr(startDate, startTime);
		const end = this.getDateTimeStr(endDate, endTime);

		const startDateConverted = moment.tz(start, timeZone).format(dayFormat + "T" + timeFormat + "Z")
		const endDayConverted = moment.tz(end, timeZone).format(dayFormat + "T" + timeFormat + "Z")

		return { start: startDateConverted, end: endDayConverted }
	}

	getDomainUrl(appDomain) { 
		if (appDomain === "witheta") {
			return `witheta.com`
		}
		return `movement.fm`;
	}

	isElementInViewport(el) {
		var rect = el.getBoundingClientRect();
	
		return rect.bottom > 0 &&
			rect.right > 0 &&
			rect.left < (window.innerWidth || document.documentElement.clientWidth) /* or $(window).width() */ &&
			rect.top < (window.innerHeight || document.documentElement.clientHeight) /* or $(window).height() */;
	}

	updateUrlParamAndReturnString(name, value) { 
		if (!window) { 
			return null;
		} 

		var currentSearch = window.location.search;
		if (currentSearch.includes(`${name}=`)) {
			const regex = new RegExp(`${name}=\\w+`, 'i')
			const fixedUrl = currentSearch.replace(regex, `${name}=${value}`);	
			return fixedUrl;
		} else { 
			if (currentSearch !== "") {
				currentSearch += "&"
			} else { 
				currentSearch += "?"
			}
			currentSearch += name + "=" + value
			return currentSearch
		}
	}

	getEnsInfo(address, provider=null) { 
		let eth = getDefaultProvider();
		let chainId = null;
		let isEthers = false;
	
		// carlos: Only use the provided provider if ENS is actually on that chain
		if (provider) {
		  if (provider.currentProvider?.chainId) {
			chainId = parseInt(provider.currentProvider.chainId);
		  } else if (provider.network?.chainId) {
			isEthers = true;
			chainId = provider.network.chainId;
		  }
	
		  if ([1, 3, 4, 5].includes(chainId)) {
			eth = isEthers ? provider  : new Web3Provider(provider.currentProvider);
		  } else {
			chainId = 1;
		  }
		}
		
		var info = {}
		return new Promise((res, rej) => {
			console.log("calling getENSInfo");
			eth.lookupAddress(address).then(ensName => {
				if (ensName) {
					console.error(ensName)
					info.name = ensName;
				    eth.getResolver(ensName).then(resolver => {
					resolver.getText('avatar').then(avatar => {
					  if (avatar && avatar.length > 0) {
						  console.error(avatar);
						  info.avatar = avatar;
					  } 
					  res(info)
					});
				  });
				}
			  });
		})
	}

	slugify(string) {
		const a = 'àáâäæãåāăąçćčđďèéêëēėęěğǵḧîïíīįìıİłḿñńǹňôöòóœøōõőṕŕřßśšşșťțûüùúūǘůűųẃẍÿýžźż·/_,:;'
		const b = 'aaaaaaaaaacccddeeeeeeeegghiiiiiiiilmnnnnoooooooooprrsssssttuuuuuuuuuwxyyzzz------'
		const p = new RegExp(a.split('').join('|'), 'g')
	  
		return string.toString().toLowerCase()
		  .replace(/\s+/g, '-') // Replace spaces with -
		  .replace(p, c => b.charAt(a.indexOf(c))) // Replace special characters
		  .replace(/&/g, '-and-') // Replace & with 'and'
		  .replace(/[^\w\-]+/g, '') // Remove all non-word characters
		  .replace(/\-\-+/g, '-') // Replace multiple - with single -
		  .replace(/^-+/, '') // Trim - from start of text
		  .replace(/-+$/, '') // Trim - from end of text
	  }

	  getDateWithExtraDays(numberOfDays) { 
		  return new Date(Date.now() + ((3600 * 1000 * 24) * numberOfDays))
	  }


	  isBotUA(phrase) {
		const BOT_UA = [
			'\\+https:\\/\\/developers.google.com\\/\\+\\/web\\/snippet\\/',
			'googlebot',
			'baiduspider',
			'gurujibot',
			'yandexbot',
			'slurp',
			'msnbot',
			'bingbot',
			'facebookexternalhit',
			'linkedinbot',
			'twitterbot',
			'slackbot',
			'telegrambot',
			'applebot',
			'pingdom',
			'tumblr',
		  ]
		const regex = new RegExp(`(${BOT_UA.join('|')})`, 'ig')
		const isBot = phrase ? regex.test(phrase.toLowerCase()) : false
		return isBot;
	  }
}
